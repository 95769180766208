import React from 'react'
import { v4 } from 'uuid'
import { Button, Box, HStack, Tag, TagLeftIcon, TagLabel } from '@chakra-ui/react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GiCutDiamond, GiEternalLove, GiAmericanShield } from 'react-icons/gi'
import { useTheme } from '@emotion/react'
import { format } from 'date-fns'

import LandingPage, {
  Header,
  Gallery,
  Features,
  Faqs,
  Video,
  Testimonies,
} from '../components/landingPage'

import {
  H1,
  P,
  Image,
  WhatsappButton,
  Article,
  Hero,
  HeroBody,
  HeroTitle,
  HeroSubtitle,
  H3,
  ColVideo,
  ColInfo,
  RowContent,
  Section,
  FeatureList,
  FeatureItem,
} from '../ui'
import FloatingBook from '../components/floatingBook'

import Layout from '../components/layout'
import { SectionTitle, Title } from '../components/texts'
import DividerVertical from '../components/dividerVertical'

function GalleryPage({ data }) {
  const { t } = useTranslation()

  return (
    <Layout disableWhatsapp title="Gallery">
      <Hero
        justifyContent="center"
        minHeight={{ base: '50vh', md: '60vh' }}
        gallery={[
          <Image
            fluid={data.hero.childImageSharp.fluid}
            layout="background"
            style={{ opacity: 0.85 }}
          />,
        ]}
      />

      <Article>
        <Section titleAlign="center" justifyContent="center">
          <DividerVertical />
          <Title color="secondary.800">Gallery</Title>
          {/* <P textAlign="center">{t('landing:jets.hero.desc')}</P> */}
          <DividerVertical />
        </Section>

        <Section spacing={10}>
          <SectionTitle>Private Jets Experience</SectionTitle>
          <Gallery
            as="div"
            paddingLeft="0"
            paddingRight="0"
            paddingTop="0"
            paddingBottom="0"
            viewPhotos={t('landing:gallery.viewPhotos')}
            images={data.galleryJets.edges.map((image) => (
              <Image key={v4()} fixed={image.node.childImageSharp.fixed} layout="background" />
            ))}
          />
        </Section>
        <Section spacing={10}>
          <SectionTitle>Luxury Yachts Experience</SectionTitle>
          <Gallery
            as="div"
            paddingLeft="0"
            paddingRight="0"
            paddingTop="0"
            paddingBottom="0"
            viewPhotos={t('landing:gallery.viewPhotos')}
            images={data.galleryYachts.edges.map((image) => (
              <Image key={v4()} fixed={image.node.childImageSharp.fixed} layout="background" />
            ))}
          />
        </Section>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "gallery/hero.jpg" }) {
      ...bannerSectionImage
    }

    galleryJets: allFile(
      filter: { relativeDirectory: { eq: "landing/jets/gallery" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    galleryYachts: allFile(
      filter: { relativeDirectory: { eq: "landing/yachts/gallery" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }
  }
`

export default GalleryPage
